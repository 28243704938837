import React from "react"

import Layout from "../components/layout/layout"
import { Projects } from "../components/projects/projects"

const IndexPage = () => (
  <Layout title="Projects">
    <Projects />
  </Layout>
)

export default IndexPage
